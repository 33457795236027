
























































.analytics {
  .dashboard-container {
    height: calc(100vh - 112px);
  }

  .p-tabmenuitem {
    width: 12.5rem;
  }

  .p-menuitem-text {
    margin: auto;
  }

  .p-tabmenu
    .p-tabmenu-nav
    .p-tabmenuitem
    .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
